import { HttpClient } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-sub-productos",
  templateUrl: "./sub-productos.component.html",
  styleUrls: ["./sub-productos.component.css"],
})
export class SubProductosComponent implements OnInit {
  @Input() subProductos: string;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() clickOutside: EventEmitter<void> = new EventEmitter<void>();

  text: string = "";

  subproductos: any[] = [];
  modoEdicion: boolean = false;
  modoAgregado: boolean = false;
  icono: any;
  subproductoSeleccionado: any = {
    descripcion: "",
    orden: "",
    createdAt: "",
    status: "",
    identificadorPlan: "",
    plan: "",
    monto: "",
    id: "",
  };

  idPlanFLow: any;
  subproductoEditado: any = {};
  subproductoAgregado: any = {};

  descripcion: string = "";
  id: string = "";
  identificadorProducto: string = "";
  imagen: string = "";
  nombreProducto: string = "";
  camposBloqueados = false;
  idSubproducto: any;
  camposBloqueadosVisDes = false;
  campoEdicionNombre = false;
  btnGuardarAgregar = false;
  constructor(private http: HttpClient, private authService: AuthService) {}

  ngOnInit() {
    this.obtenerSubProductos();
  }

  isNombreEditable: boolean = false;
  isImagenEditable: boolean = false;

  enableEdit(field: string) {
    if (field === "nombreProducto") {
      this.isNombreEditable = true;
    } else if (field === "imagenProducto") {
      this.isImagenEditable = true;
    }
  }

  consultar() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + "flow/consultarPlan";
    const idPlanFlowValue = this.subproductoSeleccionado.identificadorPlan;
    console.log(idPlanFlowValue);

    this.http
      .post<any>(
        url,
        {
          identificadorPlan: idPlanFlowValue,
        },
        { headers: headers }
      )
      .subscribe((response) => {
        if (!response.success) {
          this.camposBloqueadosVisDes = true;
          Swal.fire({
            title: "Estado",
            text: response.message,
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        } else {
          this.camposBloqueados = true;
          this.camposBloqueadosVisDes = false;
          this.btnGuardarAgregar = false;
          this.subproductoSeleccionado.identificadorPlan = response.data.planId;
          this.subproductoSeleccionado.plan = response.data.name;
          this.subproductoSeleccionado.monto = response.data.amount;
          this.subproductoSeleccionado.createdAt = response.data.createdAt;
          this.subproductoSeleccionado.descripcion = "";
          this.subproductoSeleccionado.status = "0";
        }
      });
  }

  guardarCambiosCategoria(field: string) {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + "dashboard/categorias/edit";
    if (field === "nombreProducto") {
      console.log("Nombre guardado:", this.nombreProducto);
      console.log("this.id:", this.id);
      this.isNombreEditable = false;

      this.http
        .post<any>(
          url,
          {
            id: this.id,
            nombre: this.nombreProducto,
          },
          { headers: headers }
        )
        .subscribe((response) => {
          this.icono = response.success ? "success" : "error";
          Swal.fire({
            title: "Estado",
            text: response.mensaje,
            icon: this.icono,
            confirmButtonText: "Aceptar",
          });
        });
    } else if (field === "imagenProducto") {
      console.log("Imagen guardada:", this.imagen);
      this.isImagenEditable = false;

      this.http
        .post<any>(
          url,
          {
            id: this.id,
            imagenUrl: this.imagen,
          },
          { headers: headers }
        )
        .subscribe((response) => {
          this.icono = response.success ? "success" : "error";
          Swal.fire({
            title: "Estado",
            text: response.mensaje,
            icon: this.icono,
            confirmButtonText: "Aceptar",
          });
        });
    }
  }

  obtenerSubProductos() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + "dashboard/subProductos/get";
    const data = {
      id: this.subProductos,
    };

    this.http.post(url, data, { headers }).subscribe((response: any) => {
      this.subproductos = response[0].subproductos;
      this.id = response[0].id;
      this.identificadorProducto = response[0].identificadorProducto;
      this.nombreProducto = response[0].nombreProducto;
      this.descripcion = response[0].descripcion;
      this.idSubproducto = response[0].id;
      this.imagen = response[0].imagen;
    });
  }

  editarSubproducto(subproducto: any) {
    this.subproductoSeleccionado = { ...subproducto };
    this.campoEdicionNombre = true;
    this.modoEdicion = true;
    this.modoAgregado = false;
  }

  agregarSubproducto() {
    this.btnGuardarAgregar = true;
    this.camposBloqueados = true;
    this.camposBloqueadosVisDes = true;
    this.subproductoSeleccionado = {
      descripcion: "",
      status: "",
      identificadorPlan: "",
      plan: "",
      monto: "",
    };
    this.modoEdicion = false;
    this.modoAgregado = true;
  }

  guardarEdicion() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + "dashboard/productos/edit";

    const data = {
      id: this.subproductoSeleccionado.id,
      descripcion: this.subproductoSeleccionado.descripcion,
      status: this.subproductoSeleccionado.status,
      orden: this.subproductoSeleccionado.orden,
    };
    console.log(data);
    this.http.post(url, data, { headers }).subscribe((response: any) => {
      if (response.success) {
        Swal.fire({
          title: "Edición de producto" + this.subproductoSeleccionado.plan,
          text: response.message,
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        this.obtenerSubProductos();
        this.campoEdicionNombre = false;
        this.modoEdicion = false;
        this.subproductoSeleccionado = null;
      } else {
        Swal.fire({
          title: "Estado",
          text: "Ha ocurrido un error al actualizar el producto.",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  guardarAgregado() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + "dashboard/productos/add";

    const data = {
      id: this.id,
      identificadorPlan: this.subproductoSeleccionado.identificadorPlan,
      plan: this.subproductoSeleccionado.plan,
      monto: this.subproductoSeleccionado.monto,
      descripcion: this.subproductoSeleccionado.descripcion,
      createdAt: this.subproductoSeleccionado.createdAt,
      status: this.subproductoSeleccionado.status,
    };

    this.http.post(url, data, { headers }).subscribe((response: any) => {
      if (response.success) {
        Swal.fire({
          title: "Sección Productos.",
          text: response.message,
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        this.obtenerSubProductos();
        this.modoAgregado = false;
      } else {
        Swal.fire({
          title: "Estado",
          text: response.message,
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  limpiarDatos() {
    this.subproductoSeleccionado.plan = "";
    this.subproductoSeleccionado.monto = "";
    this.subproductoSeleccionado.descripcion = "";
    this.subproductoSeleccionado.status = "0";
  }

  cancelarEdicion() {
    this.modoEdicion = false;
    this.subproductoSeleccionado = null;
  }

  cancelarAgregado() {
    this.modoAgregado = false;
  }

  onModalOverlayClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains("modal")) {
      this.clickOutside.emit();
    }
  }

  cerrarModal() {
    this.closeModal.emit();
  }

  handleEnter(event: KeyboardEvent) {
    this.text += "\n";
    console.log("pressed enter");
  }
}
