import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  totalVentas: number = 0;
  totalClientes: number = 0;
  totalSubscripciones: number = 0;
  totalCupones: number = 0;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getTotalClientes();
    this.getTotalSubscripciones();
    this.getTotalCupones();
  }

  getTotalClientes(): void {
    this.http.get<number>(`${environment.apiUrl}count/clients`).subscribe(
      (count: number) => {
        this.totalClientes = count;
      },
      (error: any) => {
        console.log('Error al obtener el total de clientes:', error);
      }
    );
  }

  getTotalSubscripciones(): void {
    this.http.get<number>(`${environment.apiUrl}count/suscripciones`).subscribe(
      (count: number) => {
        this.totalSubscripciones = count;
      },
      (error: any) => {
        console.log('Error al obtener el total de suscripciones:', error);
      }
    );
  }

  getTotalCupones(): void {
    this.http.get<number>(`${environment.apiUrl}count/coupons`).subscribe(
      (count: number) => {
        this.totalCupones = count;
      },
      (error: any) => {
        console.log('Error al obtener el total de cupones:', error);
      }
    );
  }
}
