import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";
import { VerDetallesPlanComponent } from "../ver-detalles-plan/ver-detalles-plan.component";
import Swal from "sweetalert2";

@Component({
  selector: "app-clientes",
  templateUrl: "./clientes.component.html",
  styleUrls: ["./clientes.component.css"],
})
export class ClientesComponent implements OnInit {

  clientes: any[];
  paginatedClientes: any[];
  currentPage: number;
  pageSize: number;

  filtroRut: string;
  filtroNombre: string;
  filtroEmail: string;

  modalVisible: boolean = false;
  clienteSeleccionado: any;
  header: any;

  ordenar: boolean = false;
  ordenarPorNombre: boolean = false;
  ordenarPorSuscripcion: boolean = false;
  visibleClientes: boolean = true;
 

  constructor(private http: HttpClient, private authService: AuthService) {
    this.clientes = [];
    this.paginatedClientes = [];
    this.currentPage = 1;
    this.pageSize = 8;
  }

  ngOnInit() {
    this.obtenerClientes();
  }

  abrirModal(customerId: string) {
    this.clienteSeleccionado = customerId;
    this.modalVisible = true;
  }

  closeModal() {
    this.modalVisible = false;
    this.obtenerClientes();
  }

  obtenerClientes() {
    const url = environment.apiUrl + "dashboard/clientes/get";
    const headers = this.authService.getHeaders();

    const data = {
      filtroRut: this.filtroRut,
      filtroNombre: this.filtroNombre,
      filtroEmail: this.filtroEmail,
    };
    this.http.post(url, data, { headers }).subscribe((response: any) => {
      this.clientes = response;
      this.updatePaginatedClientes();
    });
  }

  filtrarClientes() {
    const filteredProducts = this.clientes.filter((e) =>
      e.nombre.toLowerCase().includes(this.filtroEmail.toLowerCase())
    );

    if (this.filtroEmail) {
      this.paginatedClientes = filteredProducts;
    } else {
      this.paginatedClientes = this.clientes;
      this.updatePaginatedClientes();
    }
  }

  expanded = false;
  expandedStates: { [key: number]: boolean } = {};

  eliminarCliente(id: any) {
    const url = environment.apiUrl + "clientes/eliminarCLiente";
    const headers = this.authService.getHeaders();
    const data = {
      customerId: id,
    };

    Swal.fire({
      title: "Confirmación eliminación de cliente",
      text: "¿Estás seguro de que deseas eliminar este cliente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(url, data, { headers }).subscribe((response: any) => {
          if (!response.success) {
            Swal.fire({
              title: "Ha ocurrido un problema.",
              text: response.message,
              icon: "error",
            });
          } else {
            Swal.fire({
              title: "Éxito",
              text: response.message,
              icon: "success",
            });
          }

          this.obtenerClientes();
        });
      }
    });
  }

  truncarDireccion(direccion: string, longitud: number): string {
    if (direccion.length <= longitud) {
      return direccion;
    } else {
      return direccion.substring(0, longitud) + "...";
    }
  }

  updatePaginatedClientes() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedClientes = this.clientes.slice(startIndex, endIndex);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.getTotalPages()) {
        this.currentPage = page;
        this.updatePaginatedClientes();
    }

  }

  getTotalPages(): number {
      return Math.ceil(this.clientes.length / this.pageSize);
  }


  getPaginationArray(): number[] {
      return Array(this.getTotalPages())
      .fill(0)
      .map((_, index) => index + 1);
  }

  sortFechaRegistro() {
    this.visibleClientes = false;

    if (this.ordenar) {
      const ordernAsc = this.clientes.sort((a, b) => {
        if (a.registerDate < b.registerDate) {
          return -1;
        }
        if (a.registerDate > b.registerDate) {
          return 1;
        }
        return 0;
      });
      this.clientes = ordernAsc;
      this.updatePaginatedClientes();
      
    } else if (!this.ordenar) {
      const orderDes = this.clientes.sort((a, b) => {
        if (a.registerDate < b.registerDate) {
          return 1;
        }
        if (a.registerDate > b.registerDate) {
          return -1;
        }
        return 0;
      });

      this.clientes = orderDes;
      this.updatePaginatedClientes();
    }
  }

  sortNombre() {
    this.visibleClientes = false;

    if (this.ordenarPorNombre) {
      const ordernAsc = this.clientes.sort((a, b) => {
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase()) {
          return -1;
        }
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.clientes = ordernAsc;
      this.updatePaginatedClientes();
    } else if (!this.ordenar) {
      const orderDes = this.clientes.sort((a, b) => {
        if (a.nombre.toLowerCase() > b.nombre.toLowerCase()) {
          return -1;
        }
        if (a.nombre.toLowerCase() < b.nombre.toLowerCase) {
          return 1;
        }
        return 0;
      });

      this.clientes = orderDes;
      this.updatePaginatedClientes();
    }
  }

  sortSuscripcion() {
    this.visibleClientes = false;

    if (this.ordenarPorSuscripcion) {
      const ordernAsc = this.clientes.sort((a, b) => {
        if (a.estado_suscripcion.toLowerCase() < b.estado_suscripcion.toLowerCase()) {
          return -1;
        }
        if (a.estado_suscripcion.toLowerCase() > b.estado_suscripcion.toLowerCase()) {
          return 1;
        }
        return 0;
      });
      this.clientes = ordernAsc;
      this.updatePaginatedClientes();
    } else if (!this.ordenarPorSuscripcion) {
      const orderDes = this.clientes.sort((a, b) => {
        if (a.estado_suscripcion.toLowerCase() > b.estado_suscripcion.toLowerCase()) {
          return -1;
        }
        if (a.estado_suscripcion.toLowerCase() < b.estado_suscripcion.toLowerCase()) {
          return 1;
        }
        return 0;
      });

      this.clientes = orderDes;
      this.updatePaginatedClientes();
    }
  }

  filtroConSuscripcion(){
    this.visibleClientes = false;
    const filtrar = this.clientes.filter((e) => {
      if(e.estado_suscripcion === '1'){
        return e;
      }
    }); 

    this.clientes = filtrar;
    this.updatePaginatedClientes();
    this.goToPage(this.clientes.length);
    this.getTotalPages();
  }

  filtroSinSuscripcion(){
    this.visibleClientes = false;
    const filtrar = this.clientes.filter((e) => {
      if(e.estado_suscripcion == '0' || e.estado_suscripcion == '-1'){
        return e;
      }
    }); 

    this.clientes = filtrar;
    this.updatePaginatedClientes();
  }

  filtroSuscripcionTrial(){
    this.visibleClientes = false;
    const filtrarTrial = this.clientes.filter((e) => {
      if(e.estado_suscripcion === '2'){
        return e;
      }
    }); 

    this.clientes = filtrarTrial;
    this.updatePaginatedClientes();
  }



  removerFiltros() {

    if (!this.visibleClientes) {

      this.ordenar = undefined;
      this.ordenarPorNombre = undefined;
      this.ordenarPorSuscripcion = undefined;
      this.obtenerClientes(); 
      this.updatePaginatedClientes();
      this.goToPage(1);
    }
    this.visibleClientes = true;
  }
}
