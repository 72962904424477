import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { AuthService } from "src/app/services/auth.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-agregar-cupones",
  templateUrl: "./agregar-cupones.component.html",
  styleUrls: ["./agregar-cupones.component.css"],
})
export class AgregarCuponesComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() closeModal = new EventEmitter<void>();
  cupon = {
    nombreCupon: "",
    tipo_descuento: "",
    percent_off: null,
    times: "",
    max_redemptions: "",
    dateExpiration: "",
  };

  camposCompletos: boolean = false;
  constructor(private http: HttpClient, private authService: AuthService) {}

  ngOnInit() {
    this.cupon.tipo_descuento = "porciento";
  }

  getFechaMinima(): string {
    const fechaActual = new Date();
    return fechaActual.toISOString().split("T")[0];
  }

  agregarCupon() {
    if (
      this.cupon.nombreCupon &&
      this.cupon.tipo_descuento &&
      this.cupon.percent_off &&
      this.cupon.times &&
      this.cupon.max_redemptions &&
      this.cupon.dateExpiration
    ) {
      // Todos los campos están completos
      const headers = this.authService.getHeaders();
      this.http
        .post(environment.apiUrl + "coupons/create", this.cupon, {headers})
        .subscribe(
          (response) => {
            console.log("Cupón creado exitosamente", response);
            if(response['success']){
              Swal.fire({
                icon: "success",
                title: response['message'],
                text: ".",
              });
            }else{
              Swal.fire({
                icon: "error",
                title: response['message'],
                text: ".",
              });
            }

            // Restablecer los datos del formulario
            this.cupon = {
              nombreCupon: "",
              tipo_descuento: "",
              percent_off: "",
              times: "",
              max_redemptions: "",
              dateExpiration: "",
            };

            this.cerrarModal();
          },
          (error) => {
            console.error("Error al crear el cupón", error);
            Swal.fire({
              icon: "error",
              title: "¡Error!",
              text: ".",
            });
          }
        );
    } else {
      Swal.fire({
        icon: "warning",
        title: "¡Advertencia!",
        text: "Completa todos los campos antes de agregar el cupón.",
      });
    }
  }

  verificarValor() {
    if (this.cupon.percent_off < 1) {
      this.cupon.percent_off = 1;
    } else if (this.cupon.percent_off > 100) {
      this.cupon.percent_off = 100;
    }
  }

  onCloseModal() {
    this.closeModal.emit();
    this.cupon = {
      nombreCupon: "",
      tipo_descuento: "",
      percent_off: "",
      times: "",
      max_redemptions: "",
      dateExpiration: "",
    };
  }

  verificarCamposCompletos() {
    this.camposCompletos =
      !!this.cupon.nombreCupon &&
      !!this.cupon.tipo_descuento &&
      !!this.cupon.percent_off &&
      !!this.cupon.times &&
      !!this.cupon.dateExpiration &&
      !!this.cupon.max_redemptions;

    if (this.cupon.percent_off < 1) {
      this.cupon.percent_off = 1;
    } else if (this.cupon.percent_off > 100) {
      this.cupon.percent_off = 100;
    }
  }

  onModalOverlayClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains("modal-overlay")) {
      this.cerrarModal();
    }
  }

  cerrarModal() {
    this.closeModal.emit();
    this.cupon = {
      nombreCupon: "",
      tipo_descuento: "",
      percent_off: "",
      times: "",
      max_redemptions: "",
      dateExpiration: "",
    };
  }
}
