import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/login/login.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { FormularioComponent } from './component/formulario/formulario.component';
import { NavbarComponent } from './component/navbar/navbar.component';
import { LeftSidebarComponent } from './component/left-sidebar/left-sidebar.component';
import { CuponesComponent } from './component/cupones/cupones.component';
import { SubscripcionesComponent } from './component/subscripciones/subscripciones.component';
import { ClientesComponent } from './component/clientes/clientes.component';
import { AgregarCuponesComponent } from './component/agregar-cupones/agregar-cupones.component';
import { VerDetallesPlanComponent } from './component/ver-detalles-plan/ver-detalles-plan.component';
import { CommonModule } from '@angular/common';
import { ThousandsPipe } from './thousands.pipe';
import { ProductosComponent } from './component/productos/productos.component';
import { SubProductosComponent } from './component/sub-productos/sub-productos.component';
import { CustomrutDirective } from './customrut.directive';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    FormularioComponent,
    NavbarComponent,
    LeftSidebarComponent,
    CuponesComponent,
    SubscripcionesComponent,
    ClientesComponent,
    AgregarCuponesComponent,
    VerDetallesPlanComponent,
    ThousandsPipe,
    ProductosComponent,
    SubProductosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule
  ],
  providers: [CustomrutDirective],
  bootstrap: [AppComponent]
})
export class AppModule { }
