import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-subscripciones',
  templateUrl: './subscripciones.component.html',
  styleUrls: ['./subscripciones.component.css']
})
export class SubscripcionesComponent implements OnInit {
  subscripciones: any[];
  currentPage: number;
  pageSize: number;
  totalPages: number;

  constructor(private http: HttpClient, private authService: AuthService) { }

  ngOnInit() {
    this.currentPage = 1;
    this.pageSize = 8;
    this.obtenerSubscripciones();
  }

  obtenerSubscripciones() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'dashboard/suscripciones/get';
    const data = {
    };

    this.http.post(url, data, {headers}).subscribe((response: any) => {
      console.log(response);
      this.subscripciones = response;
      this.totalPages = Math.ceil(this.subscripciones.length / this.pageSize);
    });
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
    }
  }

  getPaginationArray(): number[] {
    return Array(this.totalPages).fill(0).map((x, i) => i + 1);
  }

  getTotalPages(): number {
    return this.totalPages;
  }
}
