import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import Swal from "sweetalert2";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  public nombreUsuario: string = '';
  public contrasena: string = '';

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private router: Router
  ) {}

  
  iniciarSesion() {
    const url = environment.apiUrl + 'login';
    const datos = {
      username: this.nombreUsuario,
      password: this.contrasena
    };

    this.http.post<any>(url, datos).subscribe(
      response => {
        this.authService.setToken(response.token);
        this.router.navigateByUrl('/dashboard');
      },
      error => {

        Swal.fire({
          icon: "error",
          title: error.error['message'],
          text: "",
        });
      }
    );
  }
}
