import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string | null = null;

  constructor() {
    // Inicializar el token desde el almacenamiento local al iniciar la aplicación
    this.token = localStorage.getItem('token');
  }

  // Método para establecer el token en el servicio y almacenarlo en el almacenamiento local
  setToken(token: string): void {
    this.token = token;
    localStorage.setItem('token', token);
  }

  // Método para obtener el token almacenado en el servicio
  getToken(): string | null {
    return this.token;
  }

  // Método para obtener el encabezado con el token
  getHeaders(): HttpHeaders {
    return new HttpHeaders().set('Authorization', this.token || '');
  }

  // Método para eliminar el token almacenado en el servicio y en el almacenamiento local
  clearToken(): void {
    this.token = null;
    localStorage.removeItem('token');
  }

  isLoggedIn(): boolean {
    return !!this.getToken();
  }
} 