import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { VerDetallesPlanComponent } from '../ver-detalles-plan/ver-detalles-plan.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {

  productos: any[];
  paginatedProductos: any[];
  currentPage: number;
  pageSize: number;

  filtroRut: string;
  filtroNombre: string;
  filtroEmail: string;

  modalVisible: boolean = false;
  subProductos: any

  constructor(private http: HttpClient, private authService: AuthService) {
    this.productos = [];
    this.paginatedProductos = [];
    this.currentPage = 1;
    this.pageSize = 8;
    
  }

  ngOnInit() {
    this.obtenerProductos();
  }

    editarProducto(subProducto: any) {
    this.subProductos = subProducto;
    this.modalVisible = true;
  }

  // Método para cerrar el modal
  closeModal() {
    this.modalVisible = false;
  }

  obtenerProductos() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'dashboard/productos/get';
    const data = {
      // Datos para enviar en la solicitud POST
    };
    this.http.get(url, {headers}).subscribe((response: any) => {
      this.productos = response;
    });
  }


  updatePaginatedClientes() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedProductos = this.productos.slice(startIndex, endIndex);
  }



  goToPage(page: number) {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      this.updatePaginatedClientes();
      this.obtenerProductos();
    }
  }
  
  

  getTotalPages(): number {
    return Math.ceil(this.productos.length / this.pageSize);
  }
  getPaginationArray(): number[] {
    return Array(this.getTotalPages()).fill(0).map((_, index) => index + 1);
  }

}
