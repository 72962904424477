import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ver-detalles-plan',
  templateUrl: './ver-detalles-plan.component.html',
  styleUrls: ['./ver-detalles-plan.component.css']
})
export class VerDetallesPlanComponent implements OnInit {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input() customerId: string;
  
  cliente: any;
  ciudades: any[] = [];
  comunas: any[] = [];
  historialPlanes: any[] = [];
  historialPagos: any[] = [];
  
  historialPag: string = '';
  historialPlan: string = '';

  phone: string = '';
  rut: string = '';
  name: string = '';
  lastname: string = '';
  birthdate: string = '';
  address: string = '';
  address2: string = '';
  email: string = '';
  selectedComuna: string = '';
  selectedCiudad: string = '';
  selectedPlan: string = '';
  soporteMetalico: string = '';
  cuponDescuento: string = '';
  descuento: number = 0;
  subtotal: string = '';
  total: number = 0;
  customer:string = '';


  disableCarrito = true;
  disableEditar = false;
  disableGuardar = true;
  historicoPagos: any; 
  httpClient: any;
  subscriptionId: any;

  constructor(private http: HttpClient, private authService: AuthService) { }

  ngOnInit() {
    this.cliente = [];
    this.obtenerClientes();
    this.obtenerDatosComunaCiudad();
    this.cliente.plans = this.cliente.plans || [];
  }

  editar(){
    this.disableCarrito = false;
    this.disableEditar = true;
    this.disableGuardar = false;
  }

  guardar(){
    this.disableCarrito = true;
    this.disableGuardar = true;
    this.disableEditar =false;
  }

  obtenerClientes() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'dash/cliente';
    const data = {
      customerId: this.customerId
    };
  
    this.http.post(url, data, {headers}).subscribe((response: any) => {
      this.cliente = response;           
      console.log(this.cliente);
      this.rut = response.rut;
      this.phone = response.numeroContacto;
      this.name = response.nombre;
      this.lastname = response.apellido;
      this.birthdate = response.fechaNacimiento;
      this.email = response.email;
      this.address = response.direccion;
      this.address2 = response.direccionComplementaria;
      this.selectedPlan = response.plan;
      this.cuponDescuento = response.cupon;
      this.subtotal =  response.subTotal;
      this.total = response.montoTotal;
      this.soporteMetalico = response.soporteMetalico;
      this.descuento = response.montoCupon;
      this.selectedComuna = response.id_comuna;
      this.selectedCiudad = response.id_ciudad;
      

      if (!response.plans) {
        this.historialPlan = 'vacio';
      } else {
        this.historialPlanes = response.plans;
        console.log(response.plans['0']['statusPlan']);
      }
      
      if (!response.historicoPagos) {
        this.historialPag = 'vacio';
      } else {
        this.historialPagos = response.historicoPagos;
      }
    });
  }

  onModalOverlayClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('modal')) {
      this.cerrarModal();
    }
  }

  cerrarModal() {
    this.closeModal.emit();
  }

  desuscribir(subscriptionId) {
    console.log(subscriptionId);
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'clientes/deleteSuscripcion';

    Swal.fire({
      title: 'Confirmación eliminación de plan',
      html: `
        <div style="font-size:14px;">
          <p>¿Estás seguro de que deseas eliminar el plan del usuario? Debe seleccionar una opción: </p>
          <div style="font-sieze:14px;">
          <input type="radio" id="radioInmediato" name="radioOption" value="0">
          <label for="radioInmediato"><b>Terminar suscripción inmediatamente</b></label>
          <br>
          <input type="radio" id="radioPeriodo" name="radioOption" value="1">
          <label for="radioPeriodo"><b>Terminar suscripción al finalizar el periodo</b></label>
          </div>
        </div>
        <hr>
      `,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, desuscribir a usuario',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        const radioInmediato = document.getElementById('radioInmediato') as HTMLInputElement;
        const radioPeriodo = document.getElementById('radioPeriodo') as HTMLInputElement;
        const selectedOption = radioInmediato.checked ? radioInmediato.value : radioPeriodo.checked ? radioPeriodo.value : null;
    
        if (selectedOption !== null) {
          const data = { subscriptionId: subscriptionId, option: selectedOption };
          this.http.post(url, data, {headers}).subscribe((response: any) => {
            Swal.fire({
              title: 'Éxito',
              text: response.message,
              icon: 'success'
            });
            this.obtenerClientes();
          });
        } else {
          Swal.fire({
            title: 'Error',
            text: 'Debes seleccionar una opción',
            icon: 'error'
          });
          this.obtenerClientes();
        }
      }
    });
    
    
    
  }


  obtenerDatosComunaCiudad(): void {
    
    this.http.get<any>(environment.apiUrl + 'city/comunas').subscribe(
      (dataComuna) => {
        this.comunas = dataComuna;
      },
      (error) => {
        console.log(error);
      }
    );

    this.http.get<any>(environment.apiUrl + 'city/ciudades').subscribe(
      (dataCiudad) => {
        this.ciudades = dataCiudad;
      },
      (error) => {
        console.log(error);
      }
    );

  }

  

}
