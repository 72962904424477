import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';
import { FormularioComponent } from './component/formulario/formulario.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ClientesComponent } from './component/clientes/clientes.component';
import { CuponesComponent } from './component/cupones/cupones.component';
import { SubscripcionesComponent } from './component/subscripciones/subscripciones.component';
import { ProductosComponent } from './component/productos/productos.component';
import { AuthGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: '/formulario', pathMatch: 'full' },
  // { path: '', redirectTo: '/productos', pathMatch: 'full'},
  { path: 'loginWfarm', component: LoginComponent },
  { path: 'formulario', component: FormularioComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'cupones', component: CuponesComponent, canActivate: [AuthGuard] },
  { path: 'subscripciones', component: SubscripcionesComponent, canActivate: [AuthGuard] },
  { path: 'clientes', component: ClientesComponent, canActivate: [AuthGuard] },
  { path: 'productos', component: ProductosComponent, canActivate: [AuthGuard] },
  // { path: 'formulario', component: FormularioComponent},

  { path: '**', redirectTo: '/formulario' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }