import { Directive, EventEmitter, Output, HostListener, ElementRef, forwardRef } from '@angular/core';
import * as rutHelpers from 'rut-helpers';
import { NgModel, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: '[customRut]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomrutDirective),
      multi: true
    }
  ]
})
export class CustomrutDirective implements ControlValueAccessor {
  @Output() public rutChange: EventEmitter<any>;
  private onChange: (value: any) => void;
  private onTouched: () => void;

  constructor(private el: ElementRef) {
    this.rutChange = new EventEmitter();
  }

  @HostListener('focus')
  onFocus() {
    this.onTouched();
    this.el.nativeElement.value = rutHelpers.rutClean(this.el.nativeElement.value);
  }

  @HostListener('blur')
  onBlur() {
    const value = this.el.nativeElement.value;
    this.el.nativeElement.value = rutHelpers.rutFormat(value) || '';
    this.onChange(rutHelpers.rutClean(value));
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.onChange(rutHelpers.rutClean(value));
  }

  writeValue(value: any): void {
    this.el.nativeElement.value = rutHelpers.rutFormat(value) || '';
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
