import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-cupones',
  templateUrl: './cupones.component.html',
  styleUrls: ['./cupones.component.css']
})
export class CuponesComponent implements OnInit {
  cupones: any[];
  paginatedCupones: any[];
  currentPage = 1;
  itemsPerPage = 8;
  btnEliminar: boolean = true;
  isModalOpen = false;

  constructor(private http: HttpClient, private authService: AuthService) { }

  ngOnInit() {
    this.obtenerCupones();
    this.config();
  }


  sincronizarCupones(){
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'sync/flow/cupones';

    this.http.get(url, {headers}).subscribe((response: any) => {
      Swal.fire({
        title: 'Sincronizar Cupones',
        text: response.message,
        icon: 'info'
      });
      this.obtenerCupones();
    });


  }

    eliminarCupon(id: number) {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'coupons/deleteCoupon';
    const data = { identificadorCupon: id };

    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Se eliminará el cupón seleccionado. Esta acción no se puede deshacer.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.post(url, data, { headers }).subscribe((response: any) => {
          Swal.fire({
            title: 'Éxito',
            text: response.message,
            icon: 'success'
          });
          this.obtenerCupones();
        }, (error) => {
          Swal.fire({
            title: 'Error',
            text: 'Hubo un error al eliminar el cupón.',
            icon: 'error'
          });
        });
      }
    });
  }

 
  config(){
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'configuracion/getModules';
    const data = {
      modulo: 'cupon' 
    };

    this.http.post(url, data, {headers}).subscribe((response: any) => {
      if(response['0']['status'] == 1){
        this.btnEliminar = false;
      }
    });

  }

  obtenerCupones() {
    const headers = this.authService.getHeaders();
    const url = environment.apiUrl + 'dashboard/cupones/get';
    const data = {
      
    };

    this.http.post(url, data, {headers}).subscribe((response: any) => {
      this.cupones = response || []; 
      this.updatePaginatedCupones();
    });
  }

  updatePaginatedCupones() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    this.paginatedCupones = this.cupones.slice(startIndex, endIndex);
  }

  getTotalPages(): number {
    return Math.ceil((this.cupones ? this.cupones.length : 0) / this.itemsPerPage);
  }

  getPaginationArray(): number[] {
    return Array(this.getTotalPages()).fill(0).map((x, i) => i + 1);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.getTotalPages()) {
      this.currentPage = page;
      this.updatePaginatedCupones();
    }
  }

  openModal() {
    this.isModalOpen = true;
    this.obtenerCupones();
  }

  closeModal(event: boolean) {
    this.isModalOpen = false;
    this.obtenerCupones();
  }
}
