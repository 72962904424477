import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { rutValidate } from "rut-helpers";

@Component({
  selector: "app-formulario",
  templateUrl: "./formulario.component.html",
  styleUrls: ["./formulario.component.css"],
})
export class FormularioComponent implements OnInit {
  planes: any[] = [];
  ciudades: any[] = [];
  comunas: any[] = [];
  regiones: any[] = [];
  mostrarComunas: boolean = true;
  mostrarCiudades: boolean = true;

  rut: string = "";
  name: string = "";
  lastname: string = "";
  birthdate: string = "";
  address: string = "";
  address2: string = "";
  email: string = "";
  selectedComuna: any = "";
  selectedCiudad: any = "";
  selectedRegion: any = "";
  regionSumbit: any = "";

  selectedRegionName: string = "";

  innerSelectedComuna: string = "";
  innerSelectCiudad: string = "";
  innerSelectRegion: string = "";

  selectedPlan: string = "";
  soporteMetalico: string = "";
  cuponDescuento: string = "";
  descuento: number = 0;
  subtotal: number = 0;
  total: number = 0;
  phone: string = "";

  identificadorPlanAsoc: any;

  statusCard: number = 10;
  customerId: string = "";
  urlRegisterCard: string = "";
  tokenRegisterCard: string = "";

  selectedProducto: any;
  selectedPlanes: number;
  productos: string = "";

  errorFormulario: boolean = false;
  errorFormulario2: boolean = false;
  errorFormulario3: boolean = false;
  errorFormulario4: boolean = false;
  suscricionExitosa: boolean = false;

  cuponInvalido: boolean = false;
  disableRegistrar: boolean = true;
  terminos: boolean = false;
  disableRegistrar2: boolean = true;
  disablePlan: boolean = true;
  disableCarrito: boolean = true;
  cuponHabilitado: boolean = true;
  terminosHabilitado: boolean = true;
  disableTarjeta: boolean = true;
  disableFinalizar: boolean = true;
  disableSoporte: boolean = false;
  visibleConsultar: boolean = true;
  visibleResumen: boolean = true;
  visibleTarjeta: boolean = true;
  visiblePlanes: boolean = false;
  emailInput: string = "";
  etapa: number = 1;
  activeIndex: number = 1;
  visibleEtapa1: boolean = false;
  invalidSpanVisible: boolean = false;
  visibleRegister: boolean = true;
  visibleRegion: boolean = false;
  elementRef: any;

  visibleRegionComuna: boolean = true;

  region: string;
  ciudad: string;
  comuna: string;

  filteredComunas: any[];
  filteredPlanes: any[] = [];

  constructor(private http: HttpClient, private router: Router) {}

  ngOnInit(): void {
    this.obtenerDatosPlanes();
    this.obtenerRegion();
    this.obtenerCiudad();
    this.obtenerComuna();

    this.soporteMetalico = "0";
  }

  setEtapa(etapa: number): void {
    this.etapa = etapa;
    if (etapa == 1 && this.visibleRegister) {
      this.visibleRegion = false;
      //console.log("etapa1");
    } else if (this.etapa == 2 && !this.visibleRegion) {
      this.visiblePlanes = false;
    } else if (this.etapa == 3 && !this.visiblePlanes) {
      this.visibleResumen = false;
    } else if (this.etapa == 4 && this.terminos) {
      this.terminos = false;
      this.visiblePlanes = false;
      this.visibleRegister = false;
      this.disablePlan = false;
    }
  }

  setEtapa2(etapa: number): void {
    if (this.visibleEtapa1 == false) {
      this.etapa = etapa;
    }
  }

  obtenerPlanesReload() {
    this.http.get<any>(environment.apiUrl + "planes/get").subscribe(
      (data) => {
        this.planes = data;
        console.log(data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obtenerDatosPlanes(): void {
    this.http.get<any>(environment.apiUrl + "planes/get").subscribe(
      (data) => {
        this.planes = data;
        console.log(this.planes);
      },
      (error) => {
        console.log(error);
      }
    );

    this.http.get<any>(environment.apiUrl + "city/regiones").subscribe(
      (dataRegion) => {
        this.regiones = dataRegion;
      },
      (error) => {
        console.log(error);
      }
    );

    this.http.get<any>(environment.apiUrl + "city/ciudad").subscribe(
      (dataCiudad) => {
        this.ciudades = dataCiudad;
      },
      (error) => {
        console.log(error);
      }
    );

    this.http.get<any>(environment.apiUrl + "city/comunas").subscribe(
      (dataComuna) => {
        this.comunas = dataComuna;
      },
      (error) => {
        console.log(error);
      }
    );
  }

  seleccionarPlan(subproducto: any) {
    this.identificadorPlanAsoc = subproducto.identificadorPlan;
    //console.log(this.identificadorPlanAsoc);
    this.selectedPlan = subproducto.plan;
    //console.log(this.selectedPlan);
    this.subtotal = parseFloat(subproducto.monto);
    this.soporteMetalico = "0";
    this.total = this.subtotal - this.descuento;
    this.cuponHabilitado = false;
    this.disableSoporte = false;
    this.terminosHabilitado = false;
    this.visibleRegister = false;

    if (this.cuponDescuento != "") {
      this.obtenerDescuento();
    }
  }

  reemplazarComa(numero: string): string {
    return numero.replace(",", ".");
  }

  validarRut(rut: string) {
    return rutValidate(rut);
  }

  validateRutInput(event: KeyboardEvent) {
    const allowedCharCodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 107];
    const charCode = event.charCode;

    if (!allowedCharCodes.includes(charCode)) {
      event.preventDefault();
    }
  }

  formatEmail() {
    this.email = this.email;
    const validEmail =
      this.email
        .toLowerCase()
        .match(
          /^(([^<>()[]\.,;:\s@"]+(.[^<>()[]\.,;:\s@"]+)*)|(".+"))@(([\d{1,3}.\d{1,3}.\d{1,3}.\d{1,3}])|(([a-zA-Z-\d]+.)+[a-zA-Z]{2,}))$/
        ) || "Formato de correo invalido";

    // Validar formato de correo electrónico
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!regex.test(this.email)) {
      return (this.invalidSpanVisible = true);
    } else {
      return (this.invalidSpanVisible = false);
    }
  }

  limpiarInputs() {
    this.rut = "";
    this.phone = "";
    this.name = "";
    this.lastname = "";
    this.birthdate = "";
    this.email = "";
    this.address = "";
    this.address2 = "";
    /*
    this.selectedComuna = localStorage.getItem("comuna_name");
    if (localStorage.getItem("ciudad")) {
      this.selectedCiudad = localStorage.getItem("ciudad_name");
    }
    this.selectedRegion = localStorage.getItem("region_name");
    */
    this.selectedPlanes = this.selectedPlanes;
    this.selectedPlan = this.selectedPlan;
    this.descuento = this.descuento;
    this.subtotal = this.subtotal;
    this.total = this.total;
    this.cuponDescuento = "";
    this.statusCard = 1;
    this.disableCarrito = true;
    this.visibleTarjeta = true;
    this.visibleConsultar = true;
    this.visiblePlanes = false;
    this.visibleResumen = false;
    this.soporteMetalico = "";
    this.productos = "";
    this.visibleEtapa1 = false;
  }

  limpiarInputs2() {
    this.phone = "";
    this.name = "";
    this.lastname = "";
    this.birthdate = "";
    this.email = "";
    this.address = "";
    this.address2 = "";
    this.selectedComuna = null;
    this.selectedCiudad = null;
    this.selectedRegion = null;
    this.selectedPlanes = this.selectedPlanes;
    this.selectedPlan = this.selectedPlan;
    this.descuento = this.descuento;
    this.subtotal = this.subtotal;
    this.total = this.total;
    this.cuponDescuento = "";
    this.statusCard = 1;
    this.disableCarrito = true;
    this.visibleTarjeta = true;
    this.visibleConsultar = true;
    this.visiblePlanes = false;
    this.visibleResumen = false;
    this.soporteMetalico = "";
    this.productos = "";
    this.visibleEtapa1 = false;
  }

  filtrarCiudades() {
    const selectedRegion = this.selectedRegion;
    //console.log(selectedRegion);

    // Get the regId of the selected region
    const selectedRegionId = selectedRegion ? selectedRegion.region_id : null;
    //console.log("selectedRegionId", selectedRegionId);

    if (Number(selectedRegionId) == 11) {
      this.mostrarCiudades = false;
      this.filteredComunas = this.comunas.filter(
        (comuna) => comuna.regId == 11
      );
      this.mostrarComunas = false;
    } else {
      this.mostrarCiudades = true;

      if (selectedRegionId) {
        // Filter the comunas based on the selected region's regId
        this.filteredComunas = this.comunas.filter(
          (comuna) => Number(comuna.regId) == selectedRegionId
        );
        //console.log("Filtered Comunas:", this.filteredComunas);
        // If there are matching comunas, show the comuna selector, otherwise hide it
        this.mostrarComunas = !(this.filteredComunas.length > 0);
        //console.log("mostrarComunas:", this.mostrarComunas);
      } else {
        // If no region is selected, hide the comuna selector
        this.mostrarComunas = false;
      }

      //this.selectedCiudad = null;

      this.selectedComuna = null;

      this.visiblePlanes = false;
    }
  }

  onChangeComunas() {
    this.setLocalStorage(
      this.selectedRegion,
      this.selectedCiudad,
      this.selectedComuna
    );
    this.filtrarPlanes();
    this.visibleRegionComuna = false;
  }

  filtrarPlanes() {
    const storedPlanesString = localStorage.getItem("planes");
    if (!storedPlanesString) {
      //console.log("Stored Plan String is undefined or null");
      return;
    }

    //console.log("Stored Plan String:", storedPlanesString);

    const storedPlanes = storedPlanesString.split(",");
    //console.log("Stored Plan Array:", storedPlanes);

    // Filter plans based on the selected comuna
    this.filteredPlanes = this.planes
      .map((plan) => {
        // Filter subproductos within each plan
        const matchingSubproductos = plan.subproductos.filter((subproducto) => {
          // Check if the identificadorPlan of the subproducto matches any stored plan
          return storedPlanes.includes(subproducto.identificadorPlan);
        });

        // Retain the product if it has at least one matching subproducto
        if (matchingSubproductos.length > 0) {
          // Return an object containing the product and its corresponding subproductos
          return {
            ...plan,
            subproductos: matchingSubproductos,
          };
        } else {
          // If no matching subproductos found, return null
          return null;
        }
      })
      .filter((plan) => plan !== null);

    //console.log("Filtered Planes:", this.filteredPlanes);
  }

  formularioValido(): boolean {
    return (
      this.phone &&
      this.name &&
      this.lastname &&
      this.rut &&
      this.birthdate &&
      this.email &&
      this.address &&
      this.selectedComuna &&
      this.selectedCiudad &&
      this.selectedPlan &&
      this.soporteMetalico != "0" &&
      this.terminos &&
      this.subtotal > 0
    );
  }

  formularioValido2(): boolean {
    return (
      this.phone &&
      this.name &&
      this.lastname &&
      this.rut &&
      this.birthdate &&
      this.email &&
      this.address &&
      this.selectedComuna &&
      this.selectedCiudad &&
      this.selectedRegion &&
      this.subtotal == 0
    );
  }

  mostrarMensajes2(titulo: any, mensaje: string, icono: any) {
    Swal.fire({
      title: titulo,
      text: mensaje,
      icon: icono,
      confirmButtonText: "Aceptar",
    });
  }

  mostrarMensajes(): void {
    if (this.cuponInvalido) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "El cupón ingresado no es válido. Por favor, verifica e intenta nuevamente.",
      });
    }

    if (this.errorFormulario) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Por favor, completa todos los campos requeridos antes de finalizar la compra.",
      });
    }

    if (this.errorFormulario2) {
      Swal.fire({
        icon: "error",
        title: "¡Error!",
        text: "Ese correo ya existe, intenta con otro.",
      });
    }

    if (this.errorFormulario3) {
      Swal.fire({
        icon: "info",
        title: "¡Atención!",
        text: "Este rut no existe, procede a crear tu cuenta.",
      });
    }

    if (this.errorFormulario4) {
      Swal.fire({
        icon: "success",
        title: "¡Registro Exitoso!",
        text: "Se ha registrado correctamente su solicitud para hacer su subscripción.",
      });
    }
  }

  setLocalStorage(
    selectedRegion: any,
    selectedCiudad: any,
    selectedComuna: any
  ): void {
    const { region_id, reg_nombre } = selectedRegion;

    localStorage.setItem("region", region_id);
    localStorage.setItem("region_name", reg_nombre);

    if (region_id === 11) {
      const { id: ciudad_id, ciudad } = selectedCiudad;
      const { id: comuna_id, comuna, plan } = selectedComuna;

      localStorage.setItem("ciudad", ciudad_id);
      localStorage.setItem("ciudad_name", ciudad);
      localStorage.setItem("comuna", comuna_id);
      localStorage.setItem("comuna_name", comuna);
      localStorage.setItem("planes", plan);
    } else {
      const { id: ciudad_id, ciudad } = selectedCiudad;
      const { id: comuna_id, comuna, plan } = selectedComuna;

      localStorage.setItem("ciudad", ciudad_id);
      localStorage.setItem("ciudad_name", ciudad);

      localStorage.setItem("comuna", comuna_id);
      localStorage.setItem("comuna_name", comuna);
      localStorage.setItem("planes", plan);
    }

    this.visibleRegionComuna = true;
  }

  filterPlanes(planeString: string): string[] {
    const planesArray: string[] = planeString.split(",");

    const plan: string[] = planesArray.map((plan) => plan.trim());

    return plan;
  }

  getRegionName(regionId: string): string {
    const region = this.regiones.find(
      (region) => region.region_id === regionId
    );
    return region ? region.reg_nombre : "";
  }

  consultarCliente(email: string) {
    this.visiblePlanes = false;
    this.email = email;

    if (this.email != "") {
      const datosCliente = {
        email: this.email,
      };
      this.http
        .post<any>(environment.apiUrl + "clients/get", datosCliente)
        .subscribe(
          (response) => {
            //console.log(response);
            if (response.success == true) {
              this.visibleResumen = false;
              if (
                response.message.StatusPlan == 1 ||
                response.message.StatusPlan == 2
              ) {
                this.cuponHabilitado = true;
                this.terminosHabilitado = true;
                this.terminos = true;
              }

              let tel = response.message.numeroContacto.slice(-4);
              this.phone = "569XXXX" + tel;
              this.name = response.message.nombre;
              this.lastname = response.message.apellido;

              var direccionCompleta = response.message.email;
              var parteAnterior = direccionCompleta.substring(
                0,
                direccionCompleta.indexOf("@")
              );
              var ocultado =
                parteAnterior.substring(0, parteAnterior.length - 5) + "XXXXX";

              this.email =
                ocultado +
                direccionCompleta.substring(direccionCompleta.indexOf("@"));
              this.birthdate = response.message.fechaNacimiento;
              this.address = response.message.direccion;
              this.address2 = response.message.direccionComplementaria;

              //Change región, ciudad, comuna on ConsultarCliente()
              /*
              if (
                response.message.id_region == "11" ||
                !response.message.id_region
              ) {
                const findRegion = this.regiones.find(
                  (region) => region.region_id == response.message.id_region
                );
                const findCiudad = this.ciudades.find(
                  (ciudad) => ciudad.id == response.message.id_ciudad
                );
                const findComuna = this.comunas.find(
                  (comuna) => comuna.id == response.message.id_comuna
                );

                this.selectedRegion = { reg_nombre: "Metropolitana" };
                this.mostrarComunas = false;
                this.mostrarCiudades = false;
                this.selectedCiudad = { ciudad: findCiudad.ciudad };
                this.selectedComuna = { comuna: findComuna.comuna };

                console.log(findCiudad.ciudad);
                console.log(findComuna.comuna);
              } else {
                const findRegion = this.regiones.find(
                  (region) => region.region_id == response.message.id_region
                );

                this.mostrarComunas = true;
                this.mostrarCiudades = true;
                this.selectedRegion = { reg_nombre: findRegion.reg_nombre };
                this.mostrarComunas = false;
                this.mostrarCiudades = false;
              }
              */

              this.statusCard = response.message.statusCard;
              this.customerId = response.message.customerId;
              this.urlRegisterCard = response.message.urlRegisterCard;
              this.tokenRegisterCard = response.message.tokenRegisterCard;
              this.soporteMetalico = response.message.soporteMetalico;

              this.total = Number(response.message.montoTotal)
                ? Number(response.message.montoTotal)
                : this.total;
              this.subtotal = Number(response.message.subTotal)
                ? Number(response.message.subTotal)
                : this.subtotal;
              this.descuento = Number(response.message.montoCupon)
                ? Number(response.message.montoCupon)
                : this.descuento;

              this.selectedPlan = !response.message.plan
                ? this.selectedPlan
                : response.message.plan;
              this.productos = response.message.idPlan;

              this.disableSoporte = false;
              this.errorFormulario = false;
              this.errorFormulario2 = false;
              this.errorFormulario3 = false;
              this.disableRegistrar = false;
              this.visibleTarjeta = false;
              this.visibleConsultar = false;
              this.disablePlan = false;
              this.disableRegistrar2 = false;
              this.disableFinalizar = false;
              this.visiblePlanes = false;
              this.visibleResumen = false;
              this.visibleTarjeta = false;
              this.cuponDescuento = "";
              this.visibleEtapa1 = false;
              this.cuponHabilitado = true;

              if (this.subtotal > 0) {
                this.disablePlan = true;
                this.visibleRegister = false;
                this.disableFinalizar = false;
                this.visiblePlanes = false;
                this.visibleRegister = false;
                this.disablePlan = true;
              }
            } else if (response.success == false) {
              this.visibleResumen = true;
              this.disableRegistrar = false;
              this.phone = "";
              this.emailInput = this.email;
              this.disableCarrito = false;
              this.cuponHabilitado = true;
              this.disableSoporte = true;
              this.soporteMetalico = "2";
              this.name = "";
              this.lastname = "";
              this.email = this.email;
              this.birthdate = "";
              this.address = "";
              this.address2 = "";
              /*
              if (this.selectedCiudad != "") {
                this.selectedCiudad = this.selectedCiudad.ciudad;
              }
              this.selectedComuna = this.selectedComuna.comuna;
              this.selectedRegion = this.selectedRegion.region_id;
              */
              this.cuponDescuento = "";
              this.statusCard = 0;
              this.customerId = "";
              this.urlRegisterCard = "";
              this.tokenRegisterCard = "";
              this.disableTarjeta = true;
              this.disableRegistrar2 = true;
              this.productos = "";
              this.visibleEtapa1 = true;
              this.disableFinalizar = true;
              if (this.subtotal > 0) {
                this.disablePlan = true;
                this.visibleRegister = false;
              }
            }
          },
          (error) => {
            console.error("Error al consultar el cliente:", error.message);
          }
        );
    } else {
      this.mostrarMensajes2(
        "Problemas",
        "El campo email no puede estar vacio.",
        "error"
      );
    }
  }

  registrarCliente() {
    const rut = "   ";
    const nombreCompleto = this.name;
    const apellido = "    ";
    const fechaNacimiento = "    ";
    const email = this.email;
    const direccion = this.address;
    const direccionComplementaria = this.address2;
    //const id_region = this.selectedRegion;
    const id_region = localStorage.getItem("region");
    const ciudad = localStorage.getItem("ciudad");
    const comuna = localStorage.getItem("comuna");
    let id_ciudad = "X";
    if (ciudad !== undefined) {
      id_ciudad = ciudad;
    }
    const id_comuna = comuna;
    //console.log("comuna", id_comuna);

    const numeroContacto = this.phone;

    const datosCliente = {
      rut,
      numeroContacto,
      nombreCompleto,
      apellido,
      fechaNacimiento,
      email,
      direccion,
      direccionComplementaria,
      id_region,
      id_comuna,
      id_ciudad,
    };

    this.http
      .post(environment.apiUrl + "clients/register", datosCliente)
      .subscribe(
        (response) => {
          this.selectedPlan = this.selectedPlan;
          this.subtotal = this.subtotal;
          this.total = this.total;
          this.identificadorPlanAsoc = this.identificadorPlanAsoc;

          if (response["success"] == false) {
            this.mostrarMensajes2("Error", response["message"], "warning");
            this.disableFinalizar = true;
          } else {
            this.mostrarMensajes2(
              "Exito",
              "Registro creado con exito.",
              "success"
            );
            this.disableCarrito = true;
            this.disablePlan = false;
            this.disableRegistrar2 = false;
            this.disableFinalizar = false;
            this.visiblePlanes = false;
            this.visibleResumen = false;
            this.visibleTarjeta = true;
            this.terminos = false;
            this.visibleEtapa1 = false;
            this.customerId = response["opcional"];
            this.visiblePlanes = false;
            this.visibleRegister = false;
            this.cuponHabilitado = true;
            this.disablePlan = true;
          }
        },
        (error) => {
          console.log("error", error);
          this.mostrarMensajes2("Error", error.error.message, "error");
        }
      );
  }

  registrarTarjeta() {
    window.open(
      this.urlRegisterCard + "?token=" + this.tokenRegisterCard,
      "_blank"
    );
  }

  obtenerRegion() {
    this.http.get<any>(environment.apiUrl + "city/regiones").subscribe(
      (dataRegion) => {
        this.regiones = dataRegion;
        //console.log(this.regiones);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obtenerCiudad() {
    this.http.get<any>(environment.apiUrl + "city/ciudad").subscribe(
      (dataCiudad) => {
        this.ciudades = dataCiudad;
        //console.log(this.ciudades);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obtenerComuna() {
    this.http.get<any>(environment.apiUrl + "city/comunas").subscribe(
      (dataComuna) => {
        this.comunas = dataComuna;
        //console.log(this.comunas);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  obtenerDescuento() {
    const cupon = {
      identificadorCupon: this.cuponDescuento,
    };

    if (this.cuponDescuento != "") {
      this.http.post<any>(environment.apiUrl + "coupons/get", cupon).subscribe(
        (data) => {
          if (data.success === false) {
            this.mostrarMensajes2(
              "Error",
              "El cupón ingresado no es válido. Por favor, verifica e intenta nuevamente.",
              "error"
            );
          } else {
            if (data.tipo_descuento === "porciento") {
              const descuentoPorcentaje = parseFloat(data.monto);
              this.descuento = (this.subtotal * descuentoPorcentaje) / 100;
            } else if (data.tipo_descuento === "monto") {
              this.descuento = parseFloat(data.monto);
            }
            this.total = this.subtotal - this.descuento;
            this.cuponInvalido = false;
          }
        },
        (error) => {
          this.mostrarMensajes2(
            "Error",
            "Ocurrio un error al momento de procesar su solicitud.",
            "error"
          );
          this.descuento = 0;
          this.total = this.subtotal;
        }
      );
    } else {
      this.cuponInvalido = false;
    }
  }

  format(content: string): string {
    return `<div style="text-align: left;">${content}</div>`;
  }

  finalizarCompra() {
    if (this.terminos) {
      const rut = this.rut;
      const numeroContacto = this.phone;
      const nombreCompleto = this.name;
      const apellido = this.lastname;
      const fechaNacimiento = this.birthdate;
      const email = this.email;
      const direccion = this.address;
      const direccionComplementaria = this.address2;
      //TODO: extraer datos correctos
      const id_comuna = this.selectedComuna;
      const id_ciudad = this.selectedCiudad;
      const id_region = this.selectedRegion;
      const cuponDescuento = this.cuponDescuento;
      const customerId = this.customerId;
      const soporteMetalico = "2";
      const subTotal = this.subtotal;
      const montoCupon = this.descuento;
      const montoTotal = this.total;
      const plan = this.identificadorPlanAsoc;

      const datosCompra = {
        rut,
        numeroContacto,
        nombreCompleto,
        apellido,
        fechaNacimiento,
        email,
        direccion,
        direccionComplementaria,
        id_comuna,
        id_ciudad,
        id_region,
        customerId,
        plan,
        soporteMetalico,
        cuponDescuento,
        subTotal,
        montoCupon,
        montoTotal,
      };

      this.http
        .post(environment.apiUrl + "formulario/add", datosCompra)
        .subscribe(
          (response) => {
            console.log(response);
            var data = JSON.parse(response["opcional"]);
            if (data.statusCard == 0) {
              console.log(response);
              var bodySwal =
                "Estamos a un solo paso para poder finalizar la suscripción.<br><br>" +
                'Para completar la suscripción, haga clic en el botón "Registrar Tarjeta".<br><br>' +
                "Pasos:<br>" +
                "1. Se abrirá una nueva pestaña donde podrá realizar el registro de la tarjeta..<br>" +
                "2. Una vez que su tarjeta esté registrada, su suscripción comenzará correctamente.<br><br>" +
                "<small><strong>WeFarm no almacena ningún tipo de registro de tarjeta.</strong></small>";

              Swal.fire({
                title: "Ultimo paso",
                html: this.format(bodySwal),
                icon: "info",
                showCancelButton: true,
                confirmButtonText: "Registrar Tarjeta",
                cancelButtonText: "Cerrar",
              }).then((result) => {
                if (result.isConfirmed) {
                  var url =
                    data.urlRegisterCard + "?token=" + data.tokenRegisterCard;
                  this.openPaymentPage(url);
                }
              });
              setTimeout(() => {
                Swal.close();
                location.reload();
              }, 90000);
              var url =
                data.urlRegisterCard + "?token=" + data.tokenRegisterCard;
              this.openPaymentPage(url);
            } else {
              console.log(response);
              const formData = new FormData();
              formData.append("token", data.tokenRegisterCard);

              this.http
                .post(environment.apiUrl + "flow/confirmCard", formData)
                .subscribe(
                  (response) => {
                    if (response["success"]) {
                      this.mostrarMensajes2(
                        "success",
                        response["message"],
                        "success"
                      );
                      console.log(response);
                      setTimeout(() => {
                        location.reload();
                      }, 10000);
                    } else {
                      console.log(response);
                      console.log("response error", response);
                      this.mostrarMensajes2(
                        "error",
                        response["message"],
                        "error"
                      );
                    }
                  },
                  (error) => {
                    console.log("error", error);
                    console.log(response);
                    this.mostrarMensajes2(
                      "error",
                      response["message"],
                      "error"
                    );
                  }
                );
            }
            this.disablePlan = false;
            this.disableFinalizar = true;
          },
          (error) => {
            console.error("Error al finalizar la compra:", error);
            console.log("error", error);
            this.errorFormulario2 = true;
            this.mostrarMensajes();
          }
        );
    } else {
      this.errorFormulario = true;
      this.mostrarMensajes();
    }
  }

  openPaymentPage(url: string) {
    window.open(url, "_blank");
  }
}
